/* Logo container positioning and sizing */
.logo-container {
  width: 9.5%; /* Adjust width as needed */
  position: fixed; /* Fixed position */
  top: 10px; /* Distance from the top of the viewport */
  left: 10px; /* Distance from the left of the viewport */
  z-index: 1000; /* Ensure it's on top of other content */
}

/* Logo image sizing */
.logo img {
  max-width: 100%; /* Scale with container */
  height: auto; /* Maintain aspect ratio */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .logo-container {
    width: 20%; /* Adjust width for smaller screens */
    top: 10px; /* Keep distance from the top */
    left: 10px; /* Keep distance from the left */
  }

  .logo img {
    max-width: 100%; /* Full width of its container */
  }
}
