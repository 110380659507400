/* Container for the contact page */
.contact-container {
  padding: 40px 20px;
  text-align: center;
  background-color: white; /* Light grey background */
}

.contact-title {
  font-size: 2rem; /* Simplified heading size */
  margin-bottom: 20px;
  color: #333; /* Dark grey for title text */
  font-weight: bold; /* Bold for emphasis */
}

/* Form container styling */
.form-container {
  max-width: 700px; /* Slightly reduced max-width for compactness */
  margin: 0 auto; /* Center the form */
  background-color: #ffffff; /* Pure white background for form */
  border-radius: 8px; /* Subtle rounded corners */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Soft and subtle shadow */
  padding: 20px; /* Consistent padding */
  min-height: 500px; /* Ensure enough height for the iframe */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-title {
    font-size: 1.6em; /* Smaller heading size for mobile */
  }

  .form-container {
    min-height: 400px; /* Adjust minimum height for smaller screens */
    padding: 15px; /* Reduced padding for compact layout */
  }
}
