/* General Styles */

.container {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Distribute space between items */
  max-width:auto; /* Limit width for larger screens */
  margin: 0 auto; /* Center container horizontally */
  background-color: #f9f9f9;

}

.text-container {
  flex: 1; /* Take up remaining space */
  padding: 20px;
  font-size: 1.25rem; /* 20px in rem */
  color: #403e3d; /* Dark brown text for elegance */
}

.text-container h2 {
  font-size: 2rem; /* Larger font size for heading */
  margin-bottom: 15px;
}

.text-container p {
  line-height: 1.6; /* Improved readability */
}

.image-container {
  flex: 1; /* Take up remaining space */
  display: flex;
  justify-content: center; /* Center image horizontally */
  align-items: center; /* Center image vertically */
}

.image-container img {
  max-width: 100%;
  height: auto;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text */
  }

  .text-container {
    font-size: 1rem; /* 16px in rem */
    margin-bottom: 20px; /* Space between text and image */
  }

  .image-container {
    margin-top: 20px; /* Space between text and image */
  }

  .image-container img {
    width: 100%; /* Ensure the image takes full width on small screens */
    height: auto; /* Maintain aspect ratio */
  }
}
