/* Footer Container */
.footer {
  background-color: #f7f7f7; /* Light grey for the footer background */
  padding: 20px;
  text-align: center;
  left: 0;
  bottom: 0;
  width: 100%;
}

/* Footer Content */
.footer-content {
  display:block;
  flex-direction:row;
  align-items: center; /* Center items horizontally */
}

.footer p {
  margin: 0;
  color: #333; /* Dark grey for text */
  font-size: 0.9em; /* Slightly smaller font size for minimalism */
  line-height: 1.6; /* Improved readability */
}

/* Instagram Icon Styling */
.instagram-icon {
  margin-top: 10px;
  color: #333; /* Dark grey for a subtle, cohesive look */
  font-size: 1.2em; /* Adjust size for better visibility */
  transition: color 0.3s ease; /* Smooth hover transition */
}

.instagram-icon:hover {
  color: #888; /* Lighter grey for hover effect */
}

/* Media Query for Smaller Screens */
@media only screen and (max-width: 600px) {
  .footer {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .instagram-icon {
    margin-left: 0; /* Align icon properly on smaller screens */
    font-size: 1em; /* Slightly smaller size for compact layout */
  }
}
