/* General styles */
.investment-container {
  text-align: center;
  padding: 10px;
  background-color: #f9f9f9;
}

.investment-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.package-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.package-info {
  position: relative;
  width: 47%;
  overflow: hidden;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.package-info:hover {
  /* transform: scale(1.02); */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.package-content {
  position: relative;
}

.section-image {
  width: 100%;
  height:fit-content;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.text-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  color: #fff;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 20px;
  box-sizing: border-box;
}

.package-info:hover .text-content {
  opacity: 1;
}

.price {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .package-row {
    flex-direction: column;
    gap: 30px;
  }

  .package-info {
    width: 100%;
  }
}
