/* Hero Section */
.hero-section {
  height: 100vh;
  background-image: url('./IMG_6948.jpg'); /* Full-screen hero image */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff; /* White for text */
}

.hero-content {
  padding: 20px 30px;
  text-align: center;
  background: rgba(255, 255, 255, 0.8); /* Subtle white overlay */
}

.hero-title {
  font-size: 2.5em;
  color: #333; /* Dark grey for title */
  margin-bottom: 10px;
}

.hero-subtitle {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #555; /* Medium grey for subtitle */
  font-weight: normal;
}

.hero-button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #555; /* Medium grey for button */
  color: #fff; /* White for text */
  text-transform: uppercase;
  text-decoration: none;
  transition: background-color 0.3s ease; /* Smooth transition */
}

.hero-button:hover {
  background-color: #333; /* Darker grey for hover effect */
}

/* About Section */
.about-section {
  background-color: white; /* Light grey background */
  padding: 50px 20px;
  text-align: center;
}

.about-title {
  font-size: 2em;
  margin-bottom: 15px;
  color: #333; /* Dark grey for title */
}

.about-text {
  font-size: 1em;
  margin-bottom: 20px;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
  color: #555; /* Medium grey for text */
}

.about-link {
  display: inline-block;
  padding: 8px 16px;
  font-size: 0.9em;
  background-color: #555; /* Medium grey for button */
  color: #fff; /* White for text */
  text-transform: uppercase;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.about-link:hover {
  background-color: #333; /* Darker grey for hover */
}

/* Call to Action */
.cta-section {
  background-color: white; /* Light grey */
  padding: 50px 20px;
  text-align: center;
  color: #333; /* Dark grey text */
}

.cta-title {
  font-size: 2em;
  margin-bottom: 15px;
  font-weight: bold;
}

.cta-button {
  background-color: #333; /* Dark grey for button */
  color: #fff; /* White for text */
  padding: 10px 20px;
  font-size: 1em;
  text-transform: uppercase;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #555; /* Lighter grey for hover effect */
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-title {
    font-size: 1.8em;
  }

  .hero-subtitle {
    font-size: 1em;
  }

  .about-title {
    font-size: 1.5em;
  }

  .about-text {
    font-size: 0.9em;
  }

  .gallery-title {
    font-size: 1.5em;
  }

  .cta-title {
    font-size: 1.5em;
  }
}
