/* General Menu Styles */
.menu {
  display: none; /* Hide menu by default */
  position: fixed;
  top: 0;
  right: 0;
  background-color: white; /* Match the rest of the website background */
  width: 80%; /* Adjust width as needed */
  height: 90%; /* Adjust height as needed */
  padding-top: 80px; /* Padding for spacing */
  box-sizing: border-box;
  z-index: 998;
}

.top-menu {
  display: flex; /* Flex layout for top menu */
  justify-content: flex-end; /* Align items to the right */
  background-color: white; /* Match the rest of the website background */
  padding: 20px; /* Adjust padding for layout */
  margin: 0;
  font-size: 22px; /* Text size for top menu */
  font-weight: bold; /* Bold font */
}

.top-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px; /* Space between items */
}

.top-menu ul li a {
  text-decoration: none;
  color: #3a3a3a; /* Darker grey text */
  font-size: 24px; /* Text size */
  transition: color 0.3s ease;
}

.top-menu ul li a:hover {
  color: #e76f51; /* Brighter warm orange for hover effect */
  font-weight: 700; /* Make hovered text bold */
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .menu {
    display: block;
    transform: translateX(100%); /* Hide off-screen initially */
  }

  .menu.open {
    transform: translateX(0); /* Slide in the menu */
  }

  .top-menu {
    display: none; /* Hide top menu on mobile */
  }

  .menu-toggle {
    display: block;
    cursor: pointer;
    z-index: 1000;
    position: fixed;
    top: 30px;
    right: 30px;
  }

  .hamburger {
    width: 50px;
    height: 5px;
    background-color: #3a3a3a;
    position: relative;
    transition: transform 0.3s ease-in-out;
  }

  .hamburger:before,
  .hamburger:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 5px;
    background-color: #3a3a3a;
    transition: transform 0.3s ease-in-out;
  }

  .hamburger:before {
    top: -12px;
  }

  .hamburger:after {
    top: 12px;
  }

  .menu.open .hamburger {
    background-color: transparent; /* Hide lines when menu is open */
  }

  .menu.open .hamburger:before {
    transform: rotate(-45deg) translate(-6px, 8px);
  }

  .menu.open .hamburger:after {
    transform: rotate(45deg) translate(-6px, -8px);
  }

  /* Display menu items */
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .menu li {
    margin-bottom: 40px;
  }

  .menu a {
    display: block;
    color: #3a3a3a;
    text-decoration: none;
    font-size: 28px;
    font-weight: 600;
    width: 100%;
    padding: 25px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .menu a:hover {
    background-color: #e76f51; /* Bright orange hover */
    color: #fff;
  }
}
